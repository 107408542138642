<template>
	<div class="grid">
		<div class="col-12">
            <div>
                <h3 class="titulo_grupos">Transportadoras</h3>
                <Button @click="displayCadastrarTransportadora = true" label="Cadastrar Transportadora" class="p-button-primary btn btn_cadastrar_grupo"/>
            </div>
            <Loading v-if="loading"/>

            <div class="grid" v-if="!loading" >
                <div class="col-6" v-for="(transportadora, index) in transportadoras" :key="index">
                    <div class="card ">
                        <div style="width:50%; float:left">
                            <h4>{{transportadora.nome}}</h4>
                        </div>
                        <div style="width:50%; float:left;text-align:right">
                            <button type="button" class="btn_acoes" @click="modalAlterarTransportadora(transportadora.id)" >
                                <img src="/images/icones/btn_alterar.png"/>
                            </button>
                            <button type="button" class="btn_acoes btn_excluir" @click="deletarTransportadora(transportadora.id)">
                                <img src="/images/icones/btn_excluir.png"/>
                            </button>
                        </div>
                        <div style="clear:both;"></div>
                        <div class="grid" style="margin-top:15px;">
                            <div class="col-8"><h4 style="margin-bottom:0px;">Métodos de Transporte</h4></div>
                            <div class="col-4" style="text-align:right">
                            <button class="btn_acoes" title="Adicionar Transportadora" @click="modalCadastrarMetodoTransporte(transportadora.id, transportadora.nome)">
                                    <img src="/images/icones/plus.svg"/>
                                </button>
                            </div>
                        </div>
                        <div class="corpo_card">
                            <div v-for="(metodo, index) in transportadora.metodos_transporte" :key="index" class="grid">
                                <div class="col-8">{{metodo.nome}}</div>
                                <div class="col-4">
                                    <button type="button" class="btn_acoes" @click="modalAlterarMetodoTransporte(metodo.id, metodo.nome)">
                                        <img class="btns_modal_alterar" src="/images/icones/btn_alterar.png"/>
                                    </button>
                                    <button type="button" class="btn_acoes btn_excluir" @click="deletarMetodoTransporte(metodo.id)">
                                        <img class="btns_modal_alterar_deletar" src="/images/icones/btn_excluir.png" />
                                    </button>
                                </div>
                            </div>
                        </div>    
                    </div>            
                </div>
            </div>
		</div>
	</div>

    <Dialog header="Cadastrar Transportadora" v-model:visible="displayCadastrarTransportadora" >
        <div class="">
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>Nome </label>
                    <InputText  class="input" style="width:100%" v-model="this.nome_transportadora_salvar"/>
                </div>
            </div>
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>Razão Social</label>
                    <InputText  class="input" style="width:100%" v-model="this.razao_social_transportadora_salvar"/>
                </div>
            </div>
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>CNPJ</label>
                    <InputText  class="input" style="width:100%" v-model="this.cnpj_transportadora_salvar"/>
                </div>
            </div>       
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>URL</label>
                    <InputText  class="input" style="width:100%" v-model="this.url_transportadora_salvar"/>
                </div>
            </div>                        
            <div style="text-align:center; width:100%; margin-top:10px;">                
                <Button label="Salvar" class="p-button-primary btn_salvar_grupo" @click="salvarTransportadora()"/> 
                <Button label="Fechar" class="p-button-danger btn_cancelar_grupo" @click="displayCadastrarTransportadora = false"/> 
            </div>
        </div>
    </Dialog>

    <Dialog header="Alterar Transportadora" v-model:visible="displayAlterarTransportadora" >
        <div style="width:500px;">
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>Nome</label>
                    <InputText  class="input" style="width:100%" v-model="this.nome_transportadora_alterar"/>
                </div>
            </div>
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>Razão Social</label>
                    <InputText  class="input" style="width:100%" v-model="this.razao_social_transportadora_alterar"/>
                </div>
            </div>
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>CNPJ</label>
                    <InputText  class="input" style="width:100%" v-model="this.cnpj_transportadora_alterar"/>
                </div>
            </div>  
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>URL</label>
                    <InputText  class="input" style="width:100%" v-model="this.url_transportadora_alterar"/>
                </div>
            </div>    
            <div style="text-align:center; width:100%; margin-top:10px;">                
                <Button type="button" label="Salvar" class="p-button-primary btn_salvar_grupo" @click="atualizarTransportadora()"/> 
                <Button type="button" label="Fechar" class="p-button-danger btn_cancelar_grupo" @click="fecharModalAlterarTransportadora()"/> 
            </div>
        </div>
    </Dialog>

    <Dialog header="Cadastrar Método Transporte" v-model:visible="displayCadastrarMetodoTransporte" >
        <div class="">
            <h4 style="margin-bottom:0">Transportadora:</h4>
            <h4 style="margin-top:0">{{this.nome_transportadora_selecionado}}</h4>
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>Nome do Método</label>
                    <InputText  class="input" style="width:100%" v-model="this.nome_metodo_transporte_salvar"/>
                </div>
            </div>
            <div style="text-align:center; width:100%; margin-top:10px;">                
                <Button label="Salvar" class="p-button-primary btn_salvar_grupo" @click="salvarMetodoTransporte()"/> 
                <Button label="Fechar" class="p-button-danger btn_cancelar_grupo" @click="displayCadastrarMetodoTransporte = false"/> 
            </div>
        </div>
    </Dialog>

    <Dialog header="Alterar Método Transporte" v-model:visible="displayAlterarMetodoTransporte" >
        <div style="width:500px;">
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>Método</label>
                    <InputText  class="input" style="width:100%" v-model="this.nome_metodo_transporte_alterar"/>
                </div>
            </div>

            <div style="text-align:center; width:100%; margin-top:10px;">                
                <Button type="button" label="Salvar" class="p-button-primary btn_salvar_grupo" @click="atualizarCategoria()"/> 
                <Button type="button" label="Fechar" class="p-button-danger btn_cancelar_grupo" @click="fecharModalAlterarCategoria()"/> 
            </div>
        </div>
    </Dialog>

    <Toast position="top-left" />

</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';

	export default {
    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            empresa_id:0,
            api: "",
            loading: true,
            transportadoras: null,
            nome_transportadora_salvar:"",
            nome_transportadora_alterar:"",
            razao_social_transportadora_salvar:"",
            razao_social_transportadora_alterar:"",
            cnpj_transportadora_salvar:"",
            cnpj_transportadora_alterar:"",
            url_transportadora_salvar:"",
            url_transportadora_alterar:"",

            nome_metodo_transporte_salvar:"",
            nome_metodo_transporte_alterar:"",
            
            transportadora_selecionado_id:0,
            metodo_transporte_selecionado_id:0,

            metodos_transporte_transportadora:[],

            displayCadastrarTransportadora:false,
            displayAlterarTransportadora:false,
            displayCadastrarMetodoTransporte:false,
            displayAlterarMetodoTransporte:false,
            id_grupo_editar:0,

            mostrarAdicionar: false,
        };
    },
    produtosService: null,
    quantidadePaginas: 1,
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.api = new Api();        
    },
    mounted() {
        this.listarTransportadoras();
    },
    methods: {
        listarTransportadoras() {
            this.loading = true;
            this.api.listarTransportadoras(this.token).then(data => {
                this.transportadoras = data.data.transportadoras
                this.loading = false 
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        carregarGrupo(grupo_id){
            this.$router.push({ name: 'grupo', params: { id: grupo_id } })
        },
        modalAlterarTransportadora(transportadora_id){
            this.api.carregarTransportadora(this.token,transportadora_id).then(data => {
                if(data.data.verifica_erro == false){
                    this.nome_transportadora_alterar = data.data.resposta.nome
                    this.razao_social_transportadora_alterar = data.data.resposta.razao_social
                    this.cnpj_transportadora_alterar = data.data.resposta.cnpj
                    this.url_transportadora_alterar = data.data.resposta.url
                    this.id_transportadora_alterar = data.data.resposta.id
                    this.displayAlterarTransportadora = true
                }
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            this.displayAlterarTransportadora = true;
        },

        fecharModalAlterarTransportadora(){
            this.id_transportadora_alterar  = 0
            this.nome_transportadora_alterar = ""
            this.razao_social_transportadora_alterar = ""
            this.cnpj_transportadora_alterar = ""
            this.displayAlterarTransportadora = false;
        },

        fecharModalAlterarCategoria(){
            this.nome_metodo_transporte_alterar = ''
        //    this.categorias_grupo = []
            this.displayAlterarMetodoTransporte = false;
        },


        alterarCategoria(){
            console.log('alterar')
        },

        deletarMetodoTransporte(metodo_id){
            if(confirm("Deseja deletar este Método?")){
                this.api.deletarMetodoTransporte(this.token,metodo_id,).then(data =>{
                    if(data.data.verifica_erro == false){
                        this.listarTransportadoras()
                    }else{
                        alert('Erro ao deletar.')
                    }
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            }
        },

        deletarTransportadora(transportadora_id){
            if(confirm("Deseja deletar a transportadora?")){
                this.api.deletarTransportadora(this.token,transportadora_id).then(data =>{
                    if(data.data.verifica_erro == false){
                        this.listarTransportadoras()
                    }else{
                        alert('Erro ao deletar.')
                    }
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            }
        },

        salvarTransportadora(){
            this.api.salvarTrasportadora(this.token,this.nome_transportadora_salvar, this.razao_social_transportadora_salvar,this.cnpj_transportadora_salvar, this.url_transportadora_salvar).then(data =>{
                console.log(data)
                this.nome_grupo_salvar = ""
                this.displayCadastrarGrupo = false
                this.listarTransportadoras()
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        atualizarTransportadora(){
            this.api.atualizarTransportadora(this.token, this.id_transportadora_alterar, this.nome_transportadora_alterar, this.razao_social_transportadora_alterar,this.cnpj_transportadora_alterar,this.url_transportadora_alterar).then(data =>{
                if(data.data.verifica_erro == false){
                this.id_transportadora_alterar  = 0
                this.nome_transportadora_alterar = ""
                this.razao_social_transportadora_alterar = ""
                this.cnpj_transportadora_alterar = ""
                this.displayAlterarTransportadora = false
                this.listarTransportadoras()
                }
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        atualizarCategoria(){
            this.api.updateCategoria(this.token,this.nome_categoria_alterar,this.categoria_selecionado_id,this.empresa_id).then(data =>{
                if(data.data.verifica_erro == false){
                    this.displayAlterarCategoria = false
                    this.nome_categoria_alterar = ""
                    this.categoria_selecionado_id = 0
                    this.listarTransportadoras()
                }else{
                    alert('Erro ao Cadastrar.')
                }
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        modalCadastrarMetodoTransporte(transportadora_id, nome_transportadora){
            this.transportadora_selecionado_id = transportadora_id;
            this.nome_transportadora_selecionada = nome_transportadora
            this.displayCadastrarMetodoTransporte = true;
        },

        modalAlterarMetodoTransporte(metodo_transporte_id, nome_metodo_transporte){
            this.nome_metodo_transporte_alterar = nome_metodo_transporte
            this.metodo_transporte_selecionado_id = metodo_transporte_id 
            this.displayAlterarMetodoTransporte = true;
        },
    

        salvarMetodoTransporte(){
            console.log(this.transportadora_selecionado_id)
            this.api.salvarMetodoTransporte(this.token, this.transportadora_selecionado_id, this.nome_metodo_transporte_salvar).then(data =>{
                if(data.data.verifica_erro == false){
                    this.nome_metodo_transporte_salvar = ""
                    this.transportadora_selecionado_id = 0
                    this.displayCadastrarMetodoTransporte = false
                    this.listarTransportadoras()
                }else{
                    alert('Erro ao Cadastrar.')
                }
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        listarCategoriasPorGrupo(categoria_id) {
            this.api.listarCategoriasPorGrupo(this.empresa_id,categoria_id).then(data => {
                this.categorias_grupo = data.data;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
    },
    components: { Loading }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
<style scoped lang="css">
input{
    margin: 10px;
}
.input{
    margin: 0px;
}
.btn_salvar_grupo{
    background-color:#4e87ee;
    border-color: #4e87ee;
    width:40%;
    margin-right: 2%;
}
.btn_salvar_grupo:hover{
    background-color:#4e87ee;
    border-color: #4e87ee;
}
.btn_cancelar_grupo{
    background-color:#9d9d9d;
    border-color:#9d9d9d;
    width: 40%;
    margin-left:2%;
}
.btn_cancelar_grupo:hover{
    background-color:#9d9d9d !important;
    border-color:#9d9d9d !important;

}
.titulo_pedidos{
    display: inline-block; 
    width: 40%;
}
.botoes_topo{
    display: inline-block;
    width: 50%;
    text-align:right
}
.btn{
    background-color:#4e87ee; 
    margin-top:10px;
    border:none; 
    margin-right:10px;
}
.btn:hover{
    background-color:#4e87ee !important;
}
.corpo_card{
    width:100%;
    height:100px;
    overflow-y: auto;
    overflow-x: hidden;
}
h3{
    font-size:14pt;
}
h4{
    font-size: 12pt;
    font-weight:bold;
}
h5{
    font-size: 10pt;
    margin-bottom: 5px;
}
.btn_cadastrar_grupo{
    margin-bottom:10px;
}
.btn_acoes{
    background: none;
    border: none;
    cursor:pointer;
}
.btns_modal_alterar{
    width: 20px;
}
.btns_modal_alterar_deletar{
    width: 15px;
}
</style>
